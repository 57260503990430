import React from "react";
import Spinner from "shared-components/loading-display/Spinner";
import BgLoading from "./assets/bg/bg-loading.webp";

export default function Loading() {
  return (
    <div className="w-screen h-screen">
      <img
        src={BgLoading}
        style={{ backgroundSize: "cover", backgroundPosition: "center" }}
        className="blur-md absolute w-screen h-screen"
      />
      <div className="absolute w-screen h-screen flex items-center justify-center bg-white/85">
        <Spinner classAddition={"h-5 w-5"} />
        <div className="ml-1">Loading...</div>
      </div>
    </div>
  );
}
